import * as React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import { navigate } from "gatsby"

import HeaderMenu from '@components/common/HeaderMenu'

const Header = () => {
  const [openDrawer, setOpenDrawer] = React.useState(false);
  return (
    <Container>
      <div onClick={() => navigate('/')}>
        <StaticImage
          src="../../images/logo_no-icon_black.png"
          alt="Cuddle(カドル)ロゴ"
          width={157}
        />
      </div>
      <div onClick={() => setOpenDrawer(true)}>
        <StaticImage
          src="../../images/icons/menu_black.svg"
          alt="menu_icon"
          style={{
            fontSize: "36px",
            cursor: 'pointer',
            fill: '#333333'
          }}
        />
      </div>
      <HeaderMenu openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} />
    </Container>
  )
}

const Container = styled.div`
  height: 65px;
  padding: 0px 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export default Header
