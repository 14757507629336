import React from "react";
import styled from "styled-components";

const Top = ({ category }) => {
  return (
    <Container>
      <JapaneseTitle>{category}</JapaneseTitle>
    </Container>
  );
};

const Container = styled.div`
  margin-bottom: 24px;
  margin-left: 24px;
`;

const JapaneseTitle = styled.h2`
  font-family: "Zen Maru Gothic", sans-serif;
  font-size: 20px;
  font-weight: bold;
  color: #333;
  margin: 0;
`;
export default Top;
