import * as React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import MuiBreadcrumbs from '@mui/material/Breadcrumbs'

const Breadcrumbs = props => {
  const {
    data
  } = props;
  return (
    <div className="noScrollbar">
      <MuiBreadcrumbs
        {...props}
        sx={{
          '& .MuiBreadcrumbs-ol': {
            flexWrap: 'nowrap',
            whiteSpace: 'nowrap',
            overflow: 'scroll',
          },
        }}
        separator={<Arrow>></Arrow>}
      >
        {data.map((elem, index) => {
          return data.length - 1 === index?
            <Text key={`breadcrumbs${index + 1}`}>{elem.text}</Text>:
            <StyledLink to={elem.to} key={`breadcrumbs${index + 1}`}>{elem.text}</StyledLink>;
        })}
      </MuiBreadcrumbs>
    </div>
  )
}

const Arrow = styled.span`
  font-size: 14px;
  line-height: 1.5;
  color: #999999;
`;

const StyledLink = styled(Link)`
  font-size: 14px;
  line-height: 1.5;
  color: #DBB34D;
`;

const Text = styled.span`
  font-size: 14px;
  line-height: 1.5;
  color: #666666;
  width: 100%;
`;

export default Breadcrumbs
