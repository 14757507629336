import * as React from "react";
import styled from "styled-components";
import { navigate } from "gatsby";
import format from "date-fns/format";

import ArticleCard from "@components/common/ArticleCard";
import { SectionTitle } from "@components/common/SectionTitle";

const Main = props => {
  const handleCardClick = blogId => {
    navigate(`/blog/${blogId}/`);
  };

  return (
    <Container>
      <Body>
        <SectionTitle japaneseTitle="新着記事" englishTitle="Recent Articles" />
        {props.data.map((elem, index) => {
          const node = elem.node;
          return (
            <ArticleCard
              src={node.hero_image.url}
              title={node.title}
              category={node.category}
              createdAt={format(new Date(node.publishedAt), "yyyy.MM.dd")}
              onClick={() => handleCardClick(node.blogId)}
              titleComponent="h3"
              key={`card${index + 1}`}
            />
          );
        })}
      </Body>
    </Container>
  );
};

const Container = styled.div`
  padding: 0px 24px;
`;

const Body = styled.div`
  margin-top: 200px;
  margin-bottom: 40px;
`;

export default Main;
