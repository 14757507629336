import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
import ArticleCardCarousel from "./ArticleCardCarousel"; // Import your ArticleCard component

const Carousel = ({ cards }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1, // Number of cards visible at once
    slidesToScroll: 1,
    autoplay: true, // Enable auto-scrolling
    autoplaySpeed: 5000, // Auto-scroll every 3000ms (3 seconds)
    arrows: false,
    centerMode: true,
    responsive: [
      {
        breakpoint: 768, // Mobile view
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 1024, // Tablet view
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <CarouselContainer>
      <Slider {...settings}>
        {cards.map((card, index) => (
          <ArticleCardCarousel
            key={index}
            title={card.title}
            src={card.src}
            category={card.category}
            onClick={card.onClick}
          />
        ))}
      </Slider>
    </CarouselContainer>
  );
};

const CarouselContainer = styled.div`
  .slick-dots {
    bottom: -25px;
  }
`;

export default Carousel;
