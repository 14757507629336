import * as React from "react";
import styled from "styled-components";

import CuddleAd from "@components/common/CuddleAd";

const Service = () => {
  return (
    <Container>
      <CuddleAd />
    </Container>
  );
};

const Container = styled.div`
  padding: 64px 0px 0px 0px;
`;

export default Service;
