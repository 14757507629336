import React from "react";
import styled from "styled-components";
import ArticleCard from "@components/common/ArticleCard";
import { navigate } from "gatsby";

import { SectionTitle } from "@components/common/SectionTitle";
import ButtonBlog from "@components/common/ButtonBlog";

const CategoryPreview = ({ category, english_category, blogs }) => {
  return (
    <Container>
      <SectionTitle japaneseTitle={category} englishTitle={english_category} />
      <Articles>
        {blogs.map(({ node }) => (
          <ArticleCard
            key={node.blogId}
            src={node.hero_image.url}
            title={node.title}
            category={node.category}
            createdAt={node.publishedAt}
            onClick={() => navigate(`/blog/${node.blogId}/`)}
          />
        ))}
      </Articles>
      <ButtonWrapper>
        <ButtonBlog
          width="200px"
          onClick={() => navigate(`/category/${category}/`)}
        >
          {category}一覧
        </ButtonBlog>
      </ButtonWrapper>
    </Container>
  );
};

const Container = styled.div`
  margin: 12px 24px 12px 24px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 16px;
`;

const Articles = styled.div`
  gap: 16px;
`;

export default CategoryPreview;
