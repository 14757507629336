import * as React from "react";
import { graphql } from "gatsby";

import LayoutBlog from "@components/common/LayoutBlog";
import Seo from "@components/common/SEO";
import Top from "@components/blogs/Top";
import Main from "@components/blogs/Main";
import Service from "@components/blogs/Service";
import CategoryPreview from "@components/blogs/CategoryPreview";

const Blogs = ({ data }) => {
  const latestBlogs = data.allMicrocmsBlog.edges.slice(0, 5); // Get the latest 5 blogs
  const categoryBlogs = data.categoryBlogs.edges; // Get the top 6 blogs for the category

  return (
    <LayoutBlog>
      <Seo
        title={"既婚者コラム | 既婚者向け総合メディア - Cuddle Place"}
        description={
          "既婚者合コン・サークルへの潜入レポートなど、ここでしか読めないCuddle Place（カドル プレイス）編集部のコラム一覧です。セカンドパートナー、オープンマリッジ、婚外恋愛、専用マッチングアプリ、掲示板など、今の既婚者の出会いのリアルを知りましょう。"
        }
      />
      <Top blogs={latestBlogs} />
      <Main data={data.allMicrocmsBlog.edges} />
      <Service />
      <CategoryPreview
        category="既婚者合コン"
        english_category="Maritial Parties"
        blogs={categoryBlogs}
      />
    </LayoutBlog>
  );
};

export const query = graphql`
  {
    allMicrocmsBlog(
      filter: { is_visible: { eq: true } }
      sort: { fields: [revisedAt], order: DESC }
    ) {
      edges {
        node {
          blogId
          title
          hero_image {
            url
          }
          summary
          meta_description
          content
          category
          publishedAt
        }
      }
    }
    categoryBlogs: allMicrocmsBlog(
      filter: { category: { eq: "既婚者合コン" } }
      sort: { fields: [revisedAt], order: DESC }
      limit: 6
    ) {
      edges {
        node {
          blogId
          title
          hero_image {
            url
          }
          category
          publishedAt
        }
      }
    }
  }
`;

export default Blogs;
