import * as React from "react"
import styled from "styled-components"

import Header from "@components/common/Header"
import Footer from "@components/common/Footer"

const Layout = props => {
  const {
    needHeader
  } = props;
  return (
    <Container>
      <Main>
        {needHeader !== false && <Header />}
        {props.children}
        <Footer />
      </Main>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

const Main = styled.div`
  width: 100%;
  max-width: 424px;
`;

export default Layout
