import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import styled from "styled-components";
import { navigate } from "gatsby";

import Button from "@components/common/Button";

const Cuddleplace = props => {
  return (
    <Container {...props}>
      <h3>
        <StaticImage
          src="../../images/service_title.png"
          alt="既婚者専用マッチングアプリ"
          width={192}
        />
      </h3>
      <StaticImage
        src="../../images/logo_cuddle.png"
        alt="Cuddle(カドル)"
        width={167}
        style={{ marginTop: "12px" }}
      />
      <StaticImage
        src="../../images/top_matchingapp.png"
        alt="既婚者専用マッチングアプリCuddle(カドル)とは"
        width={265}
        style={{ margin: "36px 0px" }}
      />
      <Text style={{ marginBottom: "12px" }}>
        マッチングアプリ「Cuddle（カドル）」は、結婚後も異性の友達・セカンドパートナーを簡単に探せる既婚者専用サービスです。
      </Text>
      <Text style={{ marginBottom: "36px" }}>
        AIが自動でオススメのお相手を提案してくれます。
      </Text>
      <Button
        variant="contained"
        size="large"
        font="large"
        width="100%"
        onClick={() =>
          navigate(
            "https://www.cuddle-jp.com/?utm_source=cuddle-place&utm_medium=referral&utm_campaign=cuddle-place_normal"
          )
        }
      >
        いますぐ使ってみる
      </Button>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f8f7f1;
  padding: 72px 30px;
`;

const Text = styled.div`
  font-size: 15px;
  line-height: 2;
  color: #333333;
`;

export default Cuddleplace;
