import React from "react";
import styled from "styled-components";
import ArticleCard from "@components/common/ArticleCard";
import { navigate } from "gatsby";

const Main = ({ blogs }) => {
  return (
    <Container>
      {blogs.map(({ node }) => (
        <ArticleCard
          key={node.blogId}
          src={node.hero_image.url}
          title={node.title}
          category={node.category}
          createdAt={node.publishedAt}
          onClick={() => navigate(`/blog/${node.blogId}/`)}
        />
      ))}
    </Container>
  );
};

const Container = styled.div`
  padding: 0px 24px;
`;

export default Main;
