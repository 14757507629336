import * as React from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import { navigate, Link } from "gatsby";

const Footer = () => {
  return (
    <Container>
      <LinkArea>
        <Section>
          <SectionExternalLink
            href="https://www.cuddle-jp.com/?utm_source=cuddle-place&utm_medium=referral&utm_campaign=cuddle-place_normal"
            target="_blank"
            rel="noopener noreferrer"
          >
            マッチングアプリ Cuddle
          </SectionExternalLink>
        </Section>
        <Section>
          <SectionLink to="/parties">既婚者合コン</SectionLink>
        </Section>
        <Section>
          <SectionLink to="/blogs/">既婚者コラム</SectionLink>
        </Section>
      </LinkArea>
      <div onClick={() => navigate("/")}>
        <StaticImage
          src="../../images/logo_no-icon_white.png"
          alt="Cuddle(カドル)ロゴ"
          width={171}
        />
      </div>
      <Rules>
        <RuleLink
          href="https://www.cuddle-jp.com/?utm_source=cuddle-place&utm_medium=referral&utm_campaign=cuddle-place_normalcontact"
          target="_blank"
          rel="noopener noreferrer"
        >
          お問い合わせ
        </RuleLink>
        <Devider />
        <RuleLink
          href="https://doc.cuddle-jp.com/Cuddle-Place-8b6cc2e137884986b3bc43c4e559eb8e"
          target="_blank"
          rel="noopener noreferrer"
        >
          プライバシーポリシー
        </RuleLink>
      </Rules>
      <CopyRight>Copyright(C) Third Place inc, All Right Reserved.</CopyRight>
    </Container>
  );
};

const Container = styled.div`
  background: #666666;
  padding: 30px 24px;
`;

const LinkArea = styled.div`
  width: 95%;
  margin-bottom: 55px;
  margin-top: 10px;
`;

const Section = styled.div`
  border-bottom: 1px solid #999999;
  padding: 20px 0px;
`;

const SectionExternalLink = styled.a`
  font-weight: 600;
  font-size: 14px;
  line-height: 1.5;
  color: #ffffff;
`;

const SectionLink = styled(Link)`
  font-weight: 600;
  font-size: 14px;
  line-height: 1.5;
  color: #ffffff;
`;

const Rules = styled.div`
  margin-top: 25px;
  margin-bottom: 35px;
  display: flex;
`;

const RuleLink = styled.a`
  font-family: "Noto Sans CJK JP" !important;
  font-size: 12px;
  line-height: 100%;
  color: #ffffff;
`;

const Devider = styled.div`
  border-right: 1px solid #c4c4c4;
  margin: 0px 16px;
`;

const CopyRight = styled.span`
  font-family: "Noto Sans CJK JP" !important;
  font-size: 11px;
  line-height: 100%;
  color: #ffffff;
`;

export default Footer;
