import * as React from "react";
import styled from "styled-components";
import Carousel from "@components/common/Carousel";
import { navigate } from "gatsby";

const Top = ({ blogs }) => {
  const handleCardClick = blogId => {
    navigate(`/blog/${blogId}/`);
  };

  const carouselData = blogs.map(({ node }) => ({
    title: node.title,
    src: node.hero_image.url,
    category: node.category,
    onClick: () => handleCardClick(node.blogId)
  }));

  return (
    <Container>
      <Body>
        <Section>
          <CarouselWrapper>
            <Carousel cards={carouselData} />
          </CarouselWrapper>
        </Section>
      </Body>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
`;

const Body = styled.div`
  position: relative;
`;

const Section = styled.div`
  height: 175px;
  background: #333333;
`;

const CarouselWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 0%);
  width: 100%;
`;

export default Top;
