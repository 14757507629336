import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { navigate } from "gatsby"

const PageBack = props => {
  return (
    <Container
      {...props}
      onClick={() => navigate(props.to)}
    >
      <StaticImage
        src="../../images/icons/arrow-left.png"
        alt="arrow-left"
        width={26}
      />
      <Text>
        {props.children}
      </Text>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const Text = styled.span`
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.05em;
  color: #DBB34D;
  margin-left: 7px;
`;

export default PageBack
