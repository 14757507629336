import * as React from "react";
import styled from "styled-components";

const SectionTitle = ({ japaneseTitle, englishTitle }) => {
  return (
    <TitleContainer>
      <JapaneseTitle>{japaneseTitle}</JapaneseTitle>
      <EnglishTitle>{englishTitle}</EnglishTitle>
    </TitleContainer>
  );
};

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 24px;
`;

const JapaneseTitle = styled.h2`
  font-family: "Zen Maru Gothic", sans-serif;
  font-size: 20px;
  font-weight: bold;
  color: #333;
  margin: 0;
`;

const EnglishTitle = styled.span`
  font-family: Arial, sans-serif;
  font-size: 16px;
  font-weight: normal;
  color: #3C6A7D;
  margin-top: 4px;
`;

export { SectionTitle };