import React from "react";
import { graphql } from "gatsby";
import LayoutBlog from "@components/common/LayoutBlog";
import Seo from "@components/common/SEO";

import Top from "@components/categories/Top";
import Main from "@components/categories/Main";
import Service from "@components/categories/Service";

const CategoryTemplate = ({ data, pageContext }) => {
  const { category } = pageContext;
  const blogs = data.allMicrocmsBlog.edges;

  return (
    <LayoutBlog headerTransparent={false}>
      <Seo title={`Category: ${category}`} />
      <Top category={category} />
      <Main blogs={blogs} />
      <Service />
    </LayoutBlog>
  );
};

export const query = graphql`
  query ($category: String!) {
    allMicrocmsBlog(filter: { category: { eq: $category } }, limit: 10) {
      edges {
        node {
          blogId
          title
          hero_image {
            url
          }
          category
          publishedAt
        }
      }
    }
  }
`;

export default CategoryTemplate;
