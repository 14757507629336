import * as React from "react";
import styled from "styled-components";

import CategoryIndicator from "@components/common/CategoryIndicator";

const ArticleCardCarousel = props => {
  const { title, src, category, onClick } = props;

  return (
    <CardContainer>
      <ImageWrapper onClick={onClick}>
        <Image src={src} alt={title} />
      </ImageWrapper>
      <TextContent>
        <Title onClick={onClick}>{title}</Title>
        <CategoryIndicator category={category} />
      </TextContent>
    </CardContainer>
  );
};

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 20px;
  overflow: hidden;
  transition: transform 0.2s ease-in-out;
  margin: 0 auto;
  width: 95%;
  height: 50%;
  cursor: pointer;

  &:hover {
    transform: translateY(-4px);

    h3 {
      color: #3c6a7d;
    }
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* Aspect ratio 4:3 */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
`;

const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;

const TextContent = styled.div`
  padding: 8px;
`;

const Title = styled.h3`
  font-family: "Zen Maru Gothic", sans-serif;
  font-size: 12px; /* Smaller font size */
  font-weight: bold;
  line-height: 1.4;
  color: #333;
  margin: 0 0 6px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export default ArticleCardCarousel;
