import * as React from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";

// variant: contained, outlined
// size: small, medium, large
// font: small, medium, large
// sub: text
// icon: true, false
// width: integer

const Head = props => {
  return (
    <Container {...props}>
      {props.icon && (
        <StaticImage
          src="../../images/icons/search.png"
          alt="search_icon"
          style={{
            width: "18px"
          }}
        />
      )}
      <Text {...props}>
        {props.children}
        {props.sub && <Sub {...props}>{props.sub}</Sub>}
      </Text>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: ${props =>
    props.variant === "contained" ? "0.5px solid #fff" : "0.5px solid #3C6A7D"};
  background: ${props => props.variant === "contained" && "#3C6A7D"};
  height: ${props =>
    props.size === "large"
      ? "57px"
      : props.size === "medium"
      ? "52px"
      : "45px"};
  width: ${props => props.width};
  cursor: pointer;
`;

const Text = styled.span`
  color: ${props => (props.variant === "contained" ? "#fff" : "#3C6A7D")};
  font-size: ${props => (props.font === "large" ? "16px" : "14px")};
  font-weight: ${props => (props.font === "small" ? "100" : "300")};
  margin-left: ${props => (props.icon ? "12px" : "0px")};
`;

const Sub = styled.span`
  color: ${props => (props.variant === "contained" ? "#fff" : "#3C6A7D")};
  font-size: ${props => (props.font === "large" ? "12px" : "10px")};
  font-weight: ${props => (props.font === "small" ? "100" : "300")};
  vertical-align: middle;
`;

export default Head;
